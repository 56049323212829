<template>
  <b-card>
    <div class="row mb-2 align-items-end">
      <div class="col-8 d-flex items-center">
        <h4 class="font-weight-bold">{{ title }}</h4>
      </div>
    </div>

    <b-overlay :show="isLoading" rounded opacity="0.6" spinner-small spinner-variant="primary">
      <section class="row mb-8">
        <div class="col-12 col-xl-8">
          <b-form-group
            label-cols="12"
            label-cols-lg="2"
            label-size="sm"
            :label="variable.name || variable.key"
            label-for="type-name"
            v-for="(variable, i) in form.variables"
            :key="i"
          >
            <b-form-input
              id="type-name"
              class="mb-2 mr-sm-2 mb-sm-0"
              v-model="variable.value"
            ></b-form-input>
          </b-form-group>
        </div>
      </section>
    </b-overlay>

    <div class="d-flex justify-content-center">
      <b-button class="mr-4" variant="outline-danger">取消</b-button>
      <b-overlay
        :show="isSubmmiting"
        rounded
        opacity="0.6"
        spinner-small
        spinner-variant="primary"
        class="d-inline-block"
      >
        <b-button variant="success" @click="handleSubmit">
          儲存
        </b-button>
      </b-overlay>
    </div>
  </b-card>
</template>

<script>
import { reactive } from "@vue/composition-api";
import { mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import organizationVariableApi from "@/apis/organization-variable";
import orgNameMixin from "@/mixins/organizationName"
import _ from "lodash";
// import * as consts from "@/consts";

export default {
  mixins: [orgNameMixin],
  setup() {
    const state = reactive({
      form: {
      },
    });
    const rules = {
      form: {
      },
    };

    const v$ = useVuelidate(rules, state);

    return { state, v$ };
  },
  data() {
    return {
      isLoading: false,
      isSubmmiting: false,
      form: {
        variables: [
          {
            "id": null,
            "value": null,
            "key": "LINE_LOGIN_CHANNEL_ID",
            "name": 'LINE_LOGIN_CHANNEL_ID',
          },
          {
            "id": null,
            "value": null,
            "name": 'LINE_LOGIN_CHANNEL_SECRET',
            "key": "LINE_LOGIN_CHANNEL_SECRET",
          },
          {
            "id": null,
            "value": null,
            "key": "LINE_MESSAGING_CHANNEL_ID",
            "name":'LINE_MESSAGING_CHANNEL_ID',
          },
          {
            "id": null,
            "value": null,
            "key": "LINE_MESSAGING_CHANNEL_SECRET",
            "name": "LINE_MESSAGING_CHANNEL_SECRET"
          }
        ]
      },
      hasOa: false,
      merchantTypes: [],
      lineOaId: "",
      lineLiffId: "",
      keys: [ 'LINE_LOGIN_CHANNEL_ID', 'LINE_LOGIN_CHANNEL_SECRET', 'LINE_MESSAGING_CHANNEL_ID', 'LINE_MESSAGING_CHANNEL_SECRET'],
    };
  },
  validations() {
    return {
      form: {
        name: { required },
        type: { required },
        type_name: { required },
      },
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    breadcrumbs() {
      return [
        { to: { name: 'OrganizationHome' }, name: '系統參數設定' },
      ]
    },
    orgId() {
      return this.$store.state.general.organization.id;
    },
    title() {
      return '參數設定'
    },
    webhookHost() {
      return process.env.VUE_APP_WEBHOOK_HOST
    },
    host() {
      return window.location.origin
    },
    isLine() {
      return this.state.form.type === 'LINE'
    },
  },
  mounted() {
    if (!this.hasPermission()) {
      this.$swal
        .fire({
          type: "error",
          text: "你沒有權限訪問此頁面",
          confirmButtonColor: "#d33"
        })
        .then(() => {
        });
    }
    this.fetchLineSettingVariables();
  },
  methods: {
    hasPermission() {
      return this.$permissions.has(this.$permissions.consts.LINE_SETTING_MODIFY)
    },
    async fetchLineSettingVariables() {
      try {
        this.isLoading = true;
        const { data } = await organizationVariableApi.list({
          org_code: this.orgId, keys: this.keys, merchant_type: 'line'
        })
        let mapping =  _.keyBy(data.data, 'key')
        this.form.variables = this.form.variables.map(variable => {
          if (variable.key in mapping) {
            variable.value = mapping[variable.key].value
            variable.id = mapping[variable.key].id
          }
          return variable
        })
        console.log(this.form.variables)
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false
    },
    async handleSubmit() {
      try {
        this.isSubmmiting = true;
        await organizationVariableApi.updateByKeys(this.orgId, this.form.variables);
        this.showSuccessPopup();
      } catch (error) {
        const { status, data } = error.response;
        if (status && status === 422 && data.message) {
          const html = Object.values(data.message)
            .map((m) => m[0])
            .join("<br/>");
          this.$swal.fire({
            type: "error",
            html,
          });
        }
      }
      this.isSubmmiting = false;
    },
    showSuccessPopup() {
      this.$swal
        .fire({
          type: "success",
          text:  "修改成功",
        })
        .then(() => {
          this.fetchLineSettingVariables()
        });
    },
  },
};
</script>
